import React, { useState } from "react";
import Input from "../Input";

import styles from "./ContactForm.module.css";
import logo from "../../assets/img/logo.png";
import loadingSvg from "../../assets/img/loading.gif";
import swal from "sweetalert";

interface Cotizacion {
  modeloMarca: string;
  repuesto: string;
  patente: string;
  email: string;
}

const initCotizacion = (): Cotizacion => ({
  modeloMarca: "",
  repuesto: "",
  patente: "",
  email: "",
})

export default function ContactForm() {
  const [cotizacion, setCotizacion] = useState(initCotizacion());
  const [errors, setErrors] = useState(initCotizacion());
  const [loading, setLoading] = useState(false);

  async function handleSubmit(event: React.FormEvent) {
    event?.preventDefault();
    if (validations()) {
      setLoading(true);
      const url = 'https://repuestosverdugo.cl/send-email/'; // Cambia esto a la URL correcta

      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({ ...cotizacion })
      })
        .then(data => data.text())
        .then((result) => {
          if (result === "Se ha enviado tu solicitud")
            swal("Enviado", "Recibimos tu solicitud, en breve enviaremos tu cotización", "success");
          else
            swal("Error", "No se pudo enviar tu solicitud, inténtalo más tarde", "error");

          console.log(result);
          setCotizacion(initCotizacion());
          setLoading(false);
        })
        .catch(() => {
          swal("Error", "No se pudo enviar tu solicitud, inténtalo más tarde", "error");
          setLoading(false);
        })
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setCotizacion({ ...cotizacion, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: "" });
  }

  function validations() {
    let errors: Cotizacion = initCotizacion();
    let value = true;

    if (cotizacion.modeloMarca === "") {
      errors.modeloMarca = "Debes completar este campo";
      value = false;
    }

    if (cotizacion.repuesto === "") {
      errors.repuesto = "Debes completar este campo";
      value = false;
    }

    if (cotizacion.patente === "") {
      errors.patente = "Debes completar este campo";
      value = false;
    }

    if (cotizacion.email === "") {
      errors.email = "Debes completar este campo";
      value = false;
    }

    setErrors(errors);
    return value;
  }

  return (
    <div id="form" className={styles.form}>
      <img src={logo} alt="logo" />
      <div className={styles.contactForm} data-aos="fade-up" data-aos-duration="800">
        <form onSubmit={handleSubmit}>
          {
            loading && <div className={styles.loading}>
              <img src={loadingSvg} alt="loading" />
            </div>
          }
          <header>
            <h4>¿QUÉ BUSCAS?</h4>
          </header>
          <div className={styles.inputs}>
            <Input
              name="modeloMarca"
              label="MODELO Y MARCA"
              value={cotizacion.modeloMarca}
              error={errors.modeloMarca}
              handleChange={handleChange}
            />
            <Input
              name="repuesto"
              label="REPUESTO"
              value={cotizacion.repuesto}
              error={errors.repuesto}
              handleChange={handleChange}
            />
            <Input
              name="patente"
              label="PATENTE O VIN"
              value={cotizacion.patente}
              error={errors.patente}
              handleChange={handleChange}
            />
            <Input
              name="email"
              label="CORREO"
              value={cotizacion.email}
              error={errors.email}
              handleChange={handleChange}
            />
            <button className="btn btn-danger" type="submit">Cotizar</button>
          </div>
        </form>
      </div>
    </div>
  )
}
