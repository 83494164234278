import styles from "./AboutUs.module.css";
import nosotros from "../../assets/img/nosotros.png";
import whatsapp from "../../assets/img/whatsapp.png";

export default function AboutUs() {
  return (
    <div className={styles.container}>
      <div className={styles.img} data-aos="fade-right" data-aos-duration="1500">
        <img src={nosotros} alt="nosotros" />
      </div>
      <div className={styles.text} data-aos="fade-left" data-aos-duration="1000">
        <div className={styles.title}>
          <h1>49 AÑOS DE</h1>
          <span>EXPERIENCIA</span>
        </div>
        <br></br>
        <div className={styles.content}>
          <span>Fundado por su dueño Carlos Verdugo en 1974 como taller multimarca.</span>
          <br></br>
          <span>Desde 1989 a 2021 fuimos Servicio Autorizado de la marca Nissan.</span>
          <br></br>
          <span>Del 2021 en adelante nos hemos enfocado únicamente en la venta de repuestos, expandiéndo nuestra experiencia a todas las marcas y en potenciar nuestra capacidad logística, para dar la mejor y más rápida atención a todos los rincones de Chile.</span>
        </div>
      </div>
    </div >
  )
}
