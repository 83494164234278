import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import AOS from "aos";

import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import AboutUs from "./components/AboutUs/AboutUs";

import "./App.css"
import "bootstrap/dist/css/bootstrap.min.css";
import Info from "./components/Info/Info";
import Header from "./components/Header/Header";
import Shop from "./components/Shop/Shop";
import ContactForm from "./components/ContactForm/ContactForm";

function App() {
  const [opaque, setOpaque] = useState(false);

  useEffect(() => {
    AOS.init();

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  });

  function handleScroll() {
    const scrollY = window.scrollY;
    const scrollThreshold = 10; // Puedes ajustar este umbral según tus necesidades

    if (scrollY > scrollThreshold) {
      if (!opaque) setOpaque(true);
    } else {
      if (opaque) setOpaque(false);
    }
  };

  return (
    <div className="landing">
      <Routes>
        <Route path="/" element={<Navbar opaque={opaque} selected={1} />} />
        <Route path="/nosotros" element={<Navbar opaque={opaque} selected={2} />} />
        <Route path="/tienda" element={<Navbar opaque={opaque} selected={3} />} />
      </Routes>
      <Routes>
        <Route path="/" element={<Header text="COTIZA CON NUESTROS EXPERTOS" />} />
        <Route path="/nosotros" element={<Header text="NUESTRA HISTORIA" />} />
        <Route path="/tienda" element={<Header text="COMPRA EN LÍNEA" />} />
      </Routes>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nosotros" element={<AboutUs />} />
        <Route path="/tienda" element={<Shop />} />
      </Routes>
      <Info />
      <ContactForm />
    </div>
  );
}

export default App;
