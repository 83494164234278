import styles from "./Shop.module.css";
import tienda from "../../assets/img/tienda.png";
import whatsapp from "../../assets/img/whatsapp.png";

export default function Shop() {
  return (
    <div className={styles.container}>
      <div className={styles.img} data-aos="fade-right" data-aos-duration="1500">
        <img src={tienda} alt="home" />
      </div>
      <div className={styles.text} data-aos="fade-left" data-aos-duration="1000">
        <div className={styles.title}>
          <h1>ENCUENTRA<br></br>TU <span>REPUESTO</span></h1>
          <a href="https://web.whatsapp.com/send?l=en&phone=56993204577&text=Hola,%20necesito%20cotizar%20el%20siguiente%20repuesto" target="_blank" rel="noopener noreferrer"><img src={whatsapp} alt="whatsapp" /></a>
        </div>
        <br></br>
        <div className={styles.content}>
          <span>DE MOMENTO NUESTRA TIENDA ES EXCLUSIVA PARA <a href="https://WWW.REPUESTOSPARANISSAN.CL" target="_blank" rel="noopener noreferrer">NISSAN</a>. PRONTO MÁS MARCAS EN NUESTRA TIENDA ONLINE.<br></br>SI QUIERES COTIZAR OTRAS MARCAS, <a href="https://web.whatsapp.com/send?l=en&phone=56993204577&text=Hola,%20necesito%20cotizar%20el%20siguiente%20repuesto" target="_blank" rel="noopener noreferrer">CONTACTANOS AQUÍ</a></span>
          <br></br>
          <a className={styles.shoplink} href="https://WWW.REPUESTOSPARANISSAN.CL" target="_blank" rel="noopener noreferrer">WWW.REPUESTOSPARANISSAN.CL</a>
        </div>
      </div>
    </div >
  )
}
