import React from 'react'

import styles from "./info.module.css";

import nissan from "../../assets/img/empresas/nissan.png";
import citroen from "../../assets/img/empresas/citroen.png";
import peugeot from "../../assets/img/empresas/peugeot.png";
import dbAutomobiles from "../../assets/img/empresas/db-automobiles.png";
import opel from "../../assets/img/empresas/opel.png";

import instagramSvg from "../../assets/svg/instagram.svg";
import facebookSvg from "../../assets/svg/facebook.svg";
import twitterSvg from "../../assets/svg/twitter.svg";
import whatsappSvg from "../../assets/svg/whatsapp.svg";

export default function Info() {
  return (
    <div className={styles.info}>
      <div className={styles.redes}>
        <a href="https://www.instagram.com/repuestosnissanverdugo/" target="_blank" rel="noreferrer noopener"><img src={instagramSvg} alt="instagram" /></a>
        <a href="https://www.facebook.com/profile.php?id=61550314960502" target="_blank" rel="noreferrer noopener"><img src={facebookSvg} alt="facebook" /></a>
        <a href="https://twitter.com/nissanverdugo" target="_blank" rel="noreferrer noopener"><img src={twitterSvg} alt="twitter" /></a>
        <a href="https://web.whatsapp.com/send?l=en&phone=56993204577&text=Hola,%20necesito%20cotizar%20el%20siguiente%20repuesto:" target="_blank" rel="noreferrer noopener"><img src={whatsappSvg} alt="whatsapp" /></a>
        <span>Rodrigo de Araya 85, San Joaquín, Santiago<br></br>Teléfono 22 223 0480</span>
      </div>
      <div className={styles.repuestos}>
        <h3>REPUESTOS ORIGINALES</h3>
        <a className={styles.shoplink} href="https://WWW.REPUESTOSPARANISSAN.CL" target="_blank" rel="noopener noreferrer"><img src={nissan} alt="nissan" /></a>
        <img src={citroen} alt="citroen" />
        <img src={peugeot} alt="peugeot" />
        <img src={dbAutomobiles} alt="dbAutomobiles" />
        <img src={opel} alt="opel" />
      </div>
      <h3>REPUESTOS ALTERNATIVOS DE TODAS<br></br>LAS MARCAS</h3>
    </div >
  )
}
