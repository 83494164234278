
import styles from "./Navbar.module.css";
import logo from "../../assets/img/logo.png";
import { Link } from "react-router-dom";

interface Props {
  opaque: boolean;
  selected: number;
}

export default function Navbar({ opaque, selected }: Props) {
  return (
    <div className={`${styles.navbar} ${opaque ? styles.opaque : ""}`}>
      <div className={styles.container}>
        <div
          className={styles.logo}
          data-aos="fade-down"
          data-aos-duration="400"
        >
          <img src={logo} alt="logo" />
        </div>
        <ul className={styles.menu}>
          <li className={selected === 1 ? styles.selected : ""} data-aos="fade-down" data-aos-delay="100" data-aos-duration="600"><Link to="/">COTIZA</Link></li>
          <li className={selected === 2 ? styles.selected : ""} data-aos="fade-down" data-aos-delay="200" data-aos-duration="600"><Link to="/nosotros">NOSOTROS</Link></li>
          <li className={selected === 3 ? styles.selected : ""} data-aos="fade-down" data-aos-delay="300" data-aos-duration="600"><Link to="/tienda">TIENDA</Link></li>
        </ul>
      </div>
    </div>
  )
}
