import React from 'react'

import styles from "./Header.module.css"

interface Props {
  text: string;
}

export default function Header({ text }: Props) {
  return (
    <header className={styles.header}>
      <div data-aos="fade-left" data-aos-delay="200" data-aos-duration="1000">
        <div className={styles.circle} >
          <div className={styles.redLines}></div>
        </div>
        <div className={`${styles.circle} ${styles.circle2}`}>
          <div className={styles.redLines}></div>
        </div>
      </div>
      <span data-aos="fade-down" data-aos-duration="600">{text}</span>
    </header>
  )
}
