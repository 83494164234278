import styles from "./Home.module.css";
import home from "../../assets/img/home.png";
import whatsapp from "../../assets/img/whatsapp.png";

export default function Home() {
  return (
    <div className={styles.container}>
      <div className={styles.img} data-aos="fade-right" data-aos-duration="1500">
        <img src={home} alt="home" />
      </div>
      <div className={styles.text} data-aos="fade-left" data-aos-duration="1000">
        <div className={styles.title}>
          <h1>ENCUENTRA<br></br>TU REPUESTO</h1>
          <span>AHORA</span>
          <a className={styles.cotiza} href="#form">COTIZA</a>
          <a className={styles.linkWhatsapp} href="https://web.whatsapp.com/send?l=en&phone=56993204577&text=Hola,%20necesito%20cotizar%20el%20siguiente%20repuesto" target="_blank" rel="noopener noreferrer"><img src={whatsapp} alt="whatsapp" /></a>
        </div>
        <br></br>
        <div className={styles.content}>
          <span><b>Respondemos en minutos!</b></span>
          <br></br>
          <span>Repuestos Originales y Alternativas.</span>
          <br></br>
          <span>Expertos en Repuestos hace más de 40 años.</span>
          <br></br>
          <span>Envíos a convenir a todo Chile</span>
          <br></br>
          <span>Local de ventas a Rodrigo de Araya, 85, San Joaquín, Santiago</span>
        </div>
      </div>
    </div >
  )
}
